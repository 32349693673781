<template>
  <v-app>
    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ title }}
            </span>
          </h5>

          <MainForm :form="form" :purpose="purpose">
            <template v-slot:main>
              <b-form-group id="input-group-activity-name">
                <label for="input-activity-name">Nama Kegiatan:</label>
                <b-form-input
                  id="input-activity-name"
                  v-model="form.activity_name"
                  placeholder="Nama Kegiatan"
                ></b-form-input>
                <small class="text-danger">{{ error.activity_name }}</small>
              </b-form-group>

              <hr />
              <!-- <div class="d-flex justify-content-between">
                <h6>Alat yang Akan Dipinjam</h6>
                <b-button
                  variant="success"
                  squared
                  @click="$bvModal.show('modal-tool-multiple')"
                >
                  <i class="fas fa-plus mr-1 text-white"></i> Alat
                </b-button>
              </div> -->
              <div class="row">
                <div class="col-sm-12">
                  <b-button
                    variant="info"
                    block
                    @click="chooseTool"
                  >
                    Tambah Alat
                  </b-button>
                </div>
                <div class="col-sm-12 pt-2">
                  <table class="table thead-light">
                    <thead class="thead-light">
                      <tr>
                        <th>No</th>
                        <th>Alat</th>
                        <th>Jumlah</th>
                        <th>Aksi</th>
                      </tr>
                    </thead>
                    <tr v-for="(tool, i) in toolCollection" :key="i">
                      <td>{{ i + 1 }}</td>
                      <td>{{ tool.name }}</td>
                      <td>{{ tool.amount }}</td>
                      <td>
                        <b-button
                          variant="success"
                          v-b-tooltip.hover
                          title="Edit Jumlah"
                          @click="editTool(i)"
                          size="sm"
                        >
                          <i class="fas fa-edit px-0"></i>
                        </b-button>
                        <b-button
                          class="ml-2"
                          variant="danger"
                          v-b-tooltip.hover
                          title="Hapus"
                          @click="deleteTool(i)"
                          size="sm"
                        >
                          <i class="fas fa-trash px-0"></i>
                        </b-button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <b-form-group id="input-group-buy-date">
                <label for="input-buy-date">Waktu Peminjaman: </label>
                <v-datetime-picker
                  v-model="start_time"
                  :time-picker-props="timeProps"
                  time-format="HH:mm:ss"
                  @input="setStartTime"
                ></v-datetime-picker>
                <small class="text-danger">{{ error.start_time }}</small>
              </b-form-group>

              <b-form-group id="input-group-teacher-mentor">
                <label for="input-teacher-mentor"
                  >Dosen Pembimbing: <em class="text-muted">opsional</em></label
                >
                <treeselect
                  v-model="form.teacher_mentor"
                  :multiple="false"
                  placeholder="Pilih Dosen Pembimbing"
                  :options="teachers"
                />
                <small class="text-danger">{{ error.teacher_mentor }}</small>
              </b-form-group>
            </template>
          </MainForm>
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="$router.push('/letters/nonlab-tool-loan/list')"
            >
              Batal
            </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>

    <ModalToolMultiple
      @chooseTool="chooseTool"
      @unCheckTool="unCheckTool"
      :toolCollection="toolCollection"
    />

    <!-- Modal Status -->
    <b-modal id="form-tool-value" @ok="addTool" @cancel="cancelModal">
      <template #modal-title>Form Tambah Alat</template>
      <b-form-group id="input-group-tool" label="Alat:" label-for="input-tool">
        <b-form-input
          id="input-tool"
          v-model="toolCollectionForm.name"
          placeholder="Alat"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="input-group-amount"
        label="Jumlah:"
        label-for="input-amount"
      >
        <b-form-input
          id="input-amount"
          v-model="toolCollectionForm.amount"
          placeholder="Jumlah"
          type="number"
          required
        ></b-form-input>
        <small class="text-danger">{{ toolCollectionError.amount }}</small>
      </b-form-group>
    </b-modal>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import MainForm from "@/view/components/letters/Form.vue";
import ModalToolMultiple from "@/view/components/general/ModalToolMultiple.vue";
import moment from "moment";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
    purpose: String,
    currentPhoto: String,
  },
  components: {
    MainForm,
    ModalToolMultiple,
  },
  data() {
    return {
      formData: new FormData(),
      menuDate1: false,
      error: {
        semester_id: "",
        activity_name: "",
        tools: "",
        start_time: "",
        end_time: "",
        teacher_mentor: "",
      },
      start_time: null,
      timeProps: {
        useSeconds: false,
        ampmInTitle: true,
      },
      current_photo: "/img/default/blank.jpg",
      default_photo: "/img/default/blank.jpg",
      semesters: [],
      toolCollection: [],
      // toolCollectionForm: {
      //   id: "",
      //   name: "",
      //   amount: "",
      // },
      toolCollectionForm: {
        name: "",
        amount: "",
      },
      toolCollectionError: {
        amount: "",
        name: "",
      },
      indexToolSelected: 0,
      toolPurpose: "add",
      teachers: [],
    };
  },
  methods: {
    // chooseTool(data) {
    //   this.toolPurpose = "add";
    //   console.log("data", data);
    //   this.toolCollectionForm.name = data.name;
    //   this.toolCollectionForm.id = data.id;
    //   this.toolCollectionForm.amount = "";
    //   this.$bvModal.show("form-tool-value");
    // },
    chooseTool() {
      this.toolPurpose = "add";
      this.toolCollectionForm.name = "";
      this.toolCollectionForm.amount = "";
      this.$bvModal.show("form-tool-value");
    },
    unCheckTool(value) {
      for (let a = 0; a < this.toolCollection.length; a++) {
        if (this.toolCollection[a].id == value.id) {
          this.toolCollection.splice(a, 1);
        }
      }
    },
    cancelModal(){
      this.$root.$emit('cancelModal', this.toolCollectionForm)
    },
    validationTool(){
      let status = true
      if(this.toolCollectionForm.amount == ""){
        status = false
        this.toolCollectionError.amount = "Kolom jumlah wajib diisi";
      }else{
        this.toolCollectionError.amount = "";
      }
      if(this.toolCollectionForm.name == ""){
        status = false
        this.toolCollectionError.name = "Kolom nama alat wajib diisi";
      }else{
        this.toolCollectionError.name = "";
      }
      return status
    },
    addTool(bvModalEvt) {
      // if (this.toolCollectionForm.amount == "") {
      //   bvModalEvt.preventDefault();
      //   Swal.fire({
      //     title: "Gagal",
      //     text: "Harap periksa kembali form",
      //     icon: "error",
      //     toast: true,
      //     position: "top-end",
      //     showConfirmButton: false,
      //     timer: 3000,
      //     timerProgressBar: true,
      //   });

      //   this.toolCollectionError.amount = "Kolom jumlah wajib diisi";
      //   throw new Error("validation error");
      // } else {
      //   this.toolCollectionError.amount = "";
      // }

      if(this.validationTool() == false){
        bvModalEvt.preventDefault();
        Swal.fire({
          title: "Gagal",
          text: "Harap periksa kembali form",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        throw new Error("validation error");
      }

      if (this.toolPurpose == "add") {
        let clone = { ...this.toolCollectionForm };
        this.toolCollection.push(clone);
        this.$toast.success("Berhasil tambah alat");
        console.log("tool", this.toolCollection);
      } else {
        this.$toast.success("Berhasil edit jumlah alat");
        // this.toolCollection[this.indexToolSelected].id =
        //   this.toolCollectionForm.id;
        this.toolCollection[this.indexToolSelected].name =
          this.toolCollectionForm.name;
        this.toolCollection[this.indexToolSelected].amount =
          this.toolCollectionForm.amount;
      }
      this.$bvModal.hide("form-tool-value");
    },
    deleteTool(index) {
      this.toolCollection.splice(index, 1);
    },
    editTool(i) {
      this.toolPurpose = "edit";
      this.indexToolSelected = i;

      this.toolCollectionForm.id = this.toolCollection[i].id;
      this.toolCollectionForm.name = this.toolCollection[i].name;
      this.toolCollectionForm.amount = this.toolCollection[i].amount;

      this.$bvModal.show("form-tool-value");
    },
    setStartTime(evt) {
      let start_time = moment(evt).format("YYYY-MM-DD HH:mm:ss");
      this.form.start_time = start_time;
    },
    async getTeacherOption() {
      let response = await module.setTreeSelect(
        "api/users?role_id=1&page_size=100"
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.teachers = response.data;
        this.teachers.unshift({
          label: "Pilih Dosen",
          id: "",
          isDisabled: true,
        });
      }
    },
    validationCheck() {
      let status = true;
      // if (this.purpose == "add") {
      //   if (
      //     this.form.hasOwnProperty("sign_image") == false ||
      //     this.form.sign_image == ""
      //   ) {
      //     this.error.sign_image = "Gambat TTD belum dipilih";
      //     status = false;

      //     this.$root.$emit("validationImageTrue");
      //     console.log("send emit");
      //   } else {
      //     this.error.sign_image = "";
      //     this.$root.$emit("validationImageFalse");
      //   }
      // }

      return status;
    },
    async formOnsubmit() {
      if (this.validationCheck() == false) {
        Swal.fire({
          title: "Gagal",
          text: "Harap periksa kembali form",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        throw new Error("Validation Error");
      }

      this.form.tools = JSON.stringify(this.toolCollection)

      for (const [key, value] of Object.entries(this.form)) {
        this.formData.append(key, value);
      }

      // Make Request
      let response = await module.submit(this.formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.$router.push("/letters/nonlab-tool-loan/list");
      }
    },
  },
  mounted() {
    this.getTeacherOption();
  },
  computed: {
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
  watch: {
    form: function (newVal, oldVal) {
      if (this.purpose == "edit") {
        this.form = this.form;
        this.current_photo = this.form.sign;
        this.default_photo = this.form.sign;

        this.start_time = this.form.start_time

        if(this.form.tools_display.length > 0){
          this.toolCollection = this.form.tools_display
        }
      }
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 180px !important;
  height: 180px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>