<template>
  <div>
    <!-- Filter -->
    <div class="row justify-content-end mt-n3">
      <div class="col-md-3">
        <treeselect
          v-model="filter.tool_category_id"
          :multiple="false"
          placeholder="Saring Kategori Alat"
          :options="tool_categories"
          @input="filterByToolCategory"
        />
      </div>
      <div class="col-md-5">
        <b-input-group>
          <template #append>
            <b-button
              variant="success"
              squared
              size="sm"
              @click="search"
              v-b-tooltip.hover
              title="Cari"
            >
              <i class="fas fa-search px-0"></i>
            </b-button>
            <b-button
              variant="danger"
              squared
              size="sm"
              @click="reset"
              v-b-tooltip.hover
              title="Reset"
            >
              <i class="fas fa-recycle px-0"></i>
            </b-button>
          </template>
          <b-form-input
            type="text"
            v-model="filter.name"
            placeholder="Cari Nama/No. Seri/No. Produk/Merk"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>
    <b-table
      striped
      hover
      :items="items"
      :fields="fields"
      class="mt-3"
      responsive
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'action' ? '15%' : '' }"
        />
      </template>

      <template #cell(photo)="data">
        <div
          class="
            d-flex
            justify-content-start
            align-items-center
            image-container
          "
        >
          <div class="image-input-wrapper" style="position: relative">
            <img class="image" :src="data.item.photo_url" />
          </div>
        </div>
      </template>

      <template #cell(status)="data">
        <b-badge
          v-if="data.item.tool_status_id == 1"
          class="btn-green text-white"
          style="cursor: pointer"
          @click="showModalStatus(data.item.id, data.item.tool_status_id)"
        >
          {{ data.item.tool_status_name }}
        </b-badge>
        <b-badge
          v-if="data.item.tool_status_id == 2"
          class="text-white"
          variant="danger"
          style="cursor: pointer"
        >
          {{ data.item.tool_status_name }}
        </b-badge>
        <b-badge
          v-if="data.item.tool_status_id == 3"
          class="text-white"
          variant="info"
          style="cursor: pointer"
        >
          {{ data.item.tool_status_name }}
        </b-badge>
        <b-badge
          v-if="data.item.tool_status_id == 4"
          class="text-white"
          variant="success"
          style="cursor: pointer"
        >
          {{ data.item.tool_status_name }}
        </b-badge>
        <b-badge
          v-if="data.item.tool_status_id == 5"
          class="text-white"
          variant="warning"
          style="cursor: pointer"
        >
          {{ data.item.tool_status_name }}
        </b-badge>
      </template>

      <template #cell(price)="data">
        <span>{{ data.item.price != 0 ? data.item.price : "" }}</span>
      </template>

      <template #cell(action)="data">
        <template v-if="purpose == 'modal-tool'">
          <b-button
            size="sm"
            class=""
            variant="primary"
            @click="
              chooseData({
                id: data.item.id,
                name: data.item.name,
              })
            "
          >
            Pilih
          </b-button>
        </template>
        <template v-if="purpose == 'modal-tool-multiple'">
          <b-form-checkbox
            :checked="data.item.checked"
            :id="`checkbox-pick-item-${data.item.id}`"
            :name="`checkbox-pick-item-${data.item.id}`"
            @change="
              chooseTool({
                id: data.item.id,
                name: data.item.name,
              }, $event)
            "
          >
          </b-form-checkbox>
        </template>
      </template>
    </b-table>

    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"
    ></b-pagination>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";

export default {
  props: {
    purpose: String,
    toolCollection: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      // filter
      filter: {
        name: "",
        tool_status_id: 1,
        tool_category_id: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "photo",
          label: "Foto",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "serial_number",
          label: "No. Seri",
          sortable: true,
        },
        {
          key: "product_number",
          label: "No. Produk",
          sortable: true,
        },
        {
          key: "price",
          label: "Harga",
          sortable: true,
        },
        {
          key: "tool_category_name",
          label: "Kategori",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      items: [],
      client_types: [],
      tool_statuses: [],
      tool_status_options: [],
      tool_categories: [],
      //formStatus
      formStatus: {
        tool_status_id: 0,
        id: 0,
      },
      // access
      btnAccess: true,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&tool_category_id=${this.filter.tool_category_id}&tool_status_id=${this.filter.tool_status_id}`;
      let response = await module.paginate(
        "api/tools",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
      this.setCheckedItem()
    },

    chooseTool(data, evt){
      console.log('evt', evt)
      console.log('data di methdo choosetool', data)
      if(evt){
        this.$emit("chooseTool", data);
      }else{
        this.$emit("unCheckTool", data);
      }
    },

    setCheckedItem() {
      if (this.toolCollection.length > 0) {
        console.log('toolCollection length', this.toolCollection.length)
        let found;
        this.items.forEach((value) => {
          found = this.toolCollection.find((x) => x.id == value.id);

          if (typeof found === "undefined") {
            value.checked = false;
          } else {
            value.checked = true;
          }
        });
      }
      // localStorage.setItem("dataSelectedPackage", JSON.stringify(this.itemProps))
    },

    async getToolCategoryOption() {
      let response = await module.setTreeSelect("api/tool-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.tool_categories = response.data;
        this.tool_categories.unshift({
          label: "Saring Kategori Alat",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getToolStatusOption() {
      let response = await module.setTreeSelect("api/tool-statuses");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.tool_status_options = response.data;
        this.tool_status_options.unshift({
          label: "Saring Status Alat",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getToolStatus() {
      this.tool_statuses = await module.get("api/tool-statuses");
    },

    filterByToolStatus(evt) {
      if (typeof evt == "undefined") {
        this.filter.tool_status_id = "";
      }
      this.pagination();
    },
    filterByToolCategory(evt) {
      if (typeof evt == "undefined") {
        this.filter.tool_category_id = "";
      }
      this.pagination();
    },

    search() {
      this.pagination();
    },
    reset() {
      this.filter.name = "";
      this.filter.tool_status_id = "";
      this.filter.tool_category_id = "";
      this.pagination();
    },

    chooseData(value, evt) {
      this.$emit("chooseData", value);
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("api/tools/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    async changeStatus() {
      let response = await module.submit(
        this.formStatus,
        `api/tools/${this.formStatus.id}/change-status`
      );
      if (response.state == "error") {
        Swal.fire({
          title: "Gagal",
          text: "Gagal edit status",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$bvModal.hide("modal-status");
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$bvModal.hide("modal-status");
        this.pagination();
      }
    },

    showModalStatus(id, tool_status_id) {
      this.formStatus.tool_status_id = tool_status_id;
      this.formStatus.id = id;

      this.$bvModal.show("modal-status");
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "6006") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Alat", route: "/tools" }]);
    this.pagination();
    this.getToolStatusOption();
    this.getToolStatus();
    this.getToolCategoryOption();
    this.checkAccess();

    this.$root.$on("cancelModal", (data) => {
      console.log('receipt root emit', data)
      for(let a = 0; a < this.items.length; a++){
        if(this.items[a].id == data.id){
          console.log('items', this.items[a].id)
          console.log('data', data.id)
          this.items[a].checked = false
          console.log('items full', this.items[a])
        }
      }

      this.items.splice(this.items.length)
    });
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}
.btn-circle {
  width: 40px;
  height: 40px;
  border-radius: 35px;
}

.image-input-wrapper {
  width: 50px !important;
  height: 50px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sr-only {
  width: 0px !important;
}
</style>
