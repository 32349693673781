<template>
  <div>
    <b-modal
      id="modal-tool-multiple"
      size="xl"
      title="Pilih Alat"
      hide-footer
    >
      <Table purpose="modal-tool-multiple" @unCheckTool="unCheckTool" @chooseTool="chooseTool" :toolCollection="toolCollection"/>
    </b-modal>
  </div>
</template>

<script>
import Table from "@/view/components/tools/Table.vue";

export default {
  props: {
      toolCollection: Array
  },

  components: {
    Table,
  },

  methods: {
    chooseTool(value) {
      this.$emit("chooseTool", value);
    },
    unCheckTool(value){
      this.$emit("unCheckTool", value)
    }
  },
};
</script>

<style>
</style>
